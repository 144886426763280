<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("manufacturingCollectList.noWarehouseSelected2") }}
    </div>
    <div
      v-else-if="selectedWarehouse?.type !== 2"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("manufacturingCollectList.thisWarehouseNotAProductionType") }}
    </div>
    <div v-else-if="products === undefined" class="col-12">
      <div v-for="i in 3" :key="i" class="nature-white-box no-min-height mb-3">
        <div class="row mt-3 mb-4 ms-2">
          <div class="col">
            <h2>
              <Skeletor :width="Math.random() * 21 + 30 + '%'" />
            </h2>
            <div>
              <span>
                <Skeletor :width="Math.random() * 10 + 10 + '%'" />
              </span>
            </div>
          </div>
        </div>
        <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
        <Skeletor
          class="p-3 my-1"
          :width="Math.random() * 30 + 70 + '%'"
          v-if="Math.random() > 0.3"
        />
        <Skeletor
          class="p-3 my-1"
          :width="Math.random() * 30 + 70 + '%'"
          v-if="Math.random() > 0.6"
        />
        <Skeletor
          class="p-3 my-1"
          :width="Math.random() * 30 + 70 + '%'"
          v-if="Math.random() > 0.9"
        />
      </div>
    </div>
    <div v-else class="col-12">
      <div v-if="products?.length === 0" class="nature-white-box no-min-height">
        <h2>{{ $t("manufacturingDailyList.productionList") }}</h2>
        <div class="m-5 p-5 text-center bold-40">
          {{ $t("manufacturingDailyList.thereIsNoPlannedProduction") }}
        </div>
      </div>
      <div v-else class="nature-white-box no-min-height">
        <h2>{{ $t("manufacturingDailyList.productionList") }}</h2>
        <one-by-one-table
          v-model:rows="rows"
          v-model:items="filteredProducts"
          :show-del="true"
          :show-add="false"
          :show-edit="false"
          type="products"
          v-on:delete="deleteItem"
        />
      </div>
    </div>
  </div>
  <Modal
    ref="removeProductionModal"
    :title="$t(`manufacturingDailyList.doYouWantToRemoveItemsFromProduction`)"
  >
    <ul v-if="removeItem">
      <li>{{ $t(`manufacturingDailyList.itemWillBeRemovedFromProduction`, {serial: removeItem.serial}) }}</li>
      <li>{{ $t("manufacturingDailyList.theMaterialsWillSetBackToTheWarehouse") }}</li>
      <li>{{ $t("manufacturingDailyList.youCantUndoThisAction2") }}</li>
    </ul>
    <p>{{ $t("incomingProducts.areYouSureYouWantToDoThis") }}</p>
    <div>
      <button
        type="button"
        class="btn btn-primary"
        v-on:click="removeItemFromPoruction"
        :class="{ 'loading-spin': removeBtn }"
        :disabled="removeBtn"
      >
        <span>{{ $t("manufacturingDailyList.removeFromProduction") }}</span>
      </button>
    </div>
  </Modal>
</template>
<script>
import OneByOneTable from "../../components/lists/OneByOneTable";
import http from "@/modules/http";
import { defineAsyncComponent } from "vue";
import ws from "@/modules/ws";
import { useStore } from "vuex";
import date from "@/modules/date";
import Modal from "@/components/modals/Modal";

export default {
  name: "ManufacturingDailyList",
  components: { Modal, OneByOneTable },
  data() {
    return {
      store: useStore(),
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Porduction",
          key: "manufacturing",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Production")
          ),
          type: "object",
          show: true,
          editable: false,
        },
        {
          name: "Sourcing",
          key: "warehouse_operation_id",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Total stock",
          key: "total_stock",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Monthly orders",
          key: "orders_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Orders trend",
          key: "orders_change",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Predicted deadline",
          key: "predicted_deadline",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Start time",
          key: "start_date",
          type: "date",
          show: true,
          editable: true,
        },
        {
          name: "Deadline",
          key: "deadline_date",
          type: "date",
          show: true,
          editable: true,
        },
        {
          name: "Premier date",
          key: "income_deadline_date",
          type: "date",
          show: true,
          editable: false,
        },
      ],
      products: undefined,
      removeBtn: false,
      removeItem: null,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    q() {
      return this.store.state.topBar.q;
    },
    filteredProducts() {
      if (this.q != null && this.q.length > 1) {
        let q = this.q.toLowerCase();
        return this.products.filter(
          (e) =>
            e.name?.toLowerCase()?.indexOf(q) > -1 ||
            e.serial?.toLowerCase()?.indexOf(q) > -1 ||
            e.sku?.toLowerCase()?.indexOf(q) > -1
        );
      }
      return this.products;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
  },
  mounted() {
    ws.init();

    ws.subscribe("modify", "production", (e) => {
      if (
        e.rowKey === "collected" ||
        e.rowKey === "in_package" ||
        e.rowKey === "quantity" ||
        e.rowKey === "need_to_sent_quantity"
      ) {
        let obj = this.itemsById[e.objectId];
        if (obj !== undefined) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, true, false);
    },
    loadData() {
      this.products = undefined;
      http.fetch("/production/daily").then((data) => {
        this.products = data;
        this.productsById = {};
        for (const product of this.products) {
          if (product.image !== null && product.image !== undefined) {
            product.image = this.mediaServer + "/" + product.image;
          }
        }
      });
    },
    deleteItem(e) {
      this.removeItem = e;
      this.$refs.removeProductionModal.showModal();
    },
    removeItemFromPoruction() {
      this.removeBtn = true;
      http.fetch("/production/remove", { id: this.removeItem.id }).then(() => {
        this.$refs.removeProductionModal.hideModal();
        this.removeItem = null;
        this.loadData();
        this.removeBtn = false;
      });
    },
  },
};
</script>
